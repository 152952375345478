import lodashCloneDeep from 'lodash.clonedeep'
import lodashDebounce from 'lodash.debounce'
import lodashDifference from 'lodash.difference'
import lodashDifferenceBy from 'lodash.differenceby'
import lodashIsEqual from 'lodash.isequal'
import lodashUniqWith from 'lodash.uniqwith'

export const cloneDeep = lodashCloneDeep
export const debounce = lodashDebounce
export const difference = lodashDifference
export const differenceBy = lodashDifferenceBy
export const isEqual = lodashIsEqual
export const uniqWith = lodashUniqWith
